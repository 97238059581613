<template>
  <article class="bg-f6" v-loading="loading">
    <header class="header">
      <div class="logo">
        <img src="../assets/logo-3.png" alt="logo">
      </div>
    </header>
    <section class="login-box">
      <div class="login-content">
        <div class="carousel">
          <el-carousel height="550px" :interval="2000">
            <el-carousel-item>
              <div class="swiper-img">
                <img src="../assets/login/carousel-1.png" alt="carousel">
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="swiper-img">
                <img src="../assets/login/carousel-2.png" alt="carousel">
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="swiper-img">
                <img src="../assets/login/carousel-3.png" alt="carousel">
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="login-medium">
          <div class="switch">
            <div :class="['list', type === 1 ? 'active' : '']" @click="typeSwitch(1)">网页端</div>
            <div :class="['list', type === 2 ? 'active' : '']" @click="typeSwitch(2)">手机端</div>
          </div>
          <div class="web" v-show="type === 1">
            <el-form ref="loginForm" :model="loginForm" :rules="loginFormRules">
              <el-form-item prop="phone">
                <el-input
                    class="input-list"
                    name="phone"
                    autocomplete="off"
                    placeholder="请输入手机号码"
                    maxlength="11"
                    v-model="loginForm.phone"
                    clearable
                    @keyup.enter.native="onLogin"
                    @change="resetLocalStorage">
                  <img slot="prefix" class="icon-prefix" src="../assets/login/icon-mobile.png"/>
                </el-input>
              </el-form-item>
              <el-form-item prop="psd">
                <el-input
                    class="input-list"
                    name="password"
                    placeholder="请输入密码"
                    maxlength="25"
                    v-model="loginForm.psd"
                    autocomplete="new-password"
                    show-password
                    clearable
                    @keyup.enter.native="onLogin"
                    @change="resetLocalStorage">
                  <img slot="prefix" class="icon-prefix" src="../assets/login/icon-password.png"/>
                </el-input>
              </el-form-item>
              <el-button class="login-btn" type="primary" @click="onLogin">登录</el-button>
            </el-form>
            <div class="space-between">
              <div class="forget-btn" @click="typeSwitch(3)">忘记密码</div>
              <el-checkbox v-model="keepAccount" @change="resetLocalStorage">记住密码</el-checkbox>
            </div>
          </div>
          <div class="ercode" v-show="type === 2">
            <img src="../assets/login/ercode.png" alt="ercode">
          </div>
          <div class="reset" v-show="type===3">
            <h4 class="reset-title">设置新密码</h4>
            <el-form ref="resetForm" :model="resetForm" :rules="resetFormRules">
              <el-form-item prop="phone">
                <el-input
                    class="input-list"
                    placeholder="请输入手机号码"
                    maxlength="11"
                    v-model="resetForm.phone"
                    clearable
                    autocomplete="off"
                    @keyup.enter.native="onReset">
                  <img slot="prefix" class="icon-prefix" src="../assets/login/icon-mobile.png"/>
                </el-input>
              </el-form-item>
              <el-form-item prop="authCode">
                <el-input
                    class="input-list"
                    placeholder="请输入验证码"
                    maxlength="6"
                    autocomplete="off"
                    v-model="resetForm.authCode"
                    @keyup.enter.native="onReset">
                  <img slot="prefix" class="icon-prefix" src="../assets/login/icon-auth-code.png"/>
                  <div slot="suffix"
                       class="auth-code"
                       :class="[timer?'cursor-not':'cursor-pointer']"
                       @click="validateAuthCode">{{authCodeText}}
                  </div>
                </el-input>
              </el-form-item>
              <el-form-item prop="newPassword">
                <el-input
                    class="input-list"
                    placeholder="请输入新密码"
                    maxlength="25"
                    v-model="resetForm.newPassword"
                    show-password
                    clearable
                    autocomplete="off"
                    @keyup.enter.native="onReset">
                  <img slot="prefix" class="icon-prefix" src="../assets/login/icon-password.png"/>
                </el-input>
              </el-form-item>
            </el-form>
            <el-button class="reset-btn__common" type="primary" @click="onReset">确定
            </el-button>
            <div class="reset-btn__common reset-btn__cancel" @click="typeSwitch(1)">返回登录</div>
          </div>
          <div class="reset" v-show="type===4">
            <h4 class="reset-title">安全验证</h4>
            <div id="nc"></div>
          </div>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="column">
        <div class="left">
          <img src="../assets/login/icon-1.png" alt="1">
        </div>
        <div>
          <div class="title">智能批改</div>
          <p>系统智能批改作业，及时反馈班级学情报告分析，可根据作业情况打回重做、作业点评，催缴作业等。</p>
        </div>
        <div class="right">
          <img src="../assets/login/icon-11.png" alt="img">
        </div>
      </div>
      <div class="column">
        <div class="left">
          <img src="../assets/login/icon-2.png" alt="2">
        </div>
        <div>
          <div class="title">分层教学</div>
          <p>老师可自由给班内学生进行分组，从而布置作业，困材施教，实现个性化教学。</p>
        </div>
        <div class="right">
          <img src="../assets/login/icon-22.png" alt="img"/>
        </div>
      </div>
      <div class="column">
        <div class="left">
          <img src="../assets/login/icon-3.png" alt="3">
        </div>
        <div>
          <div class="title">模考作业</div>
          <p>系统要求学生严格按照考试流程，一次完成模考作业，考完可由老师决定是否公布、何时公布成绩答案等。</p>
        </div>
        <div class="right">
          <img src="../assets/login/icon-33.png" alt="img"/>
        </div>
      </div>
      <div class="column">
        <div class="left">
          <img src="../assets/login/icon-4.png" alt="4">
        </div>
        <div>
          <div class="title">通用场景</div>
          <p>多终端登陆，微信小程序方便快捷，网页版教师端可用于现场为学生做点评。</p>
        </div>
        <div class="right">
          <img src="../assets/login/icon-44.png" alt="img"/>
        </div>
      </div>
    </section>
    <footer class="footer">
      <div class="tips">使用教程</div>
      <div class="row">
        <div class="col">
          <div class="img">
            <img src="../assets/login/foot-1.png" alt="img">
          </div>
          <div class="bold">开通教师账号</div>
          <p>专业客服协助老师开通</p>
        </div>
        <div class="arrow">
          <img src="../assets/login/foot-arrow.png" alt="arrow">
        </div>
        <div class="col">
          <div class="img">
            <img src="../assets/login/foot-2.png" alt="img">
          </div>
          <div class="bold">创建班级</div>
          <p>允许创建多个班级</p>
        </div>
        <div class="arrow">
          <img src="../assets/login/foot-arrow.png" alt="arrow">
        </div>
        <div class="col">
          <div class="img">
            <img src="../assets/login/foot-3.png" alt="img">
          </div>
          <div class="bold">邀请学生加入</div>
          <p>学生输入班级ID即可加入班级</p>
        </div>
        <div class="arrow">
          <img src="../assets/login/foot-arrow.png" alt="arrow">
        </div>
        <div class="col">
          <div class="img">
            <img src="../assets/login/foot-4.png" alt="img">
          </div>
          <div class="bold">分层教学</div>
          <p>可选择创建班内学生分组分层教学，因材施教</p>
        </div>
      </div>
    </footer>
    <el-backtop target="#app"></el-backtop>
  </article>

</template>

<script>

  import {getAliYunConfigParam, getAuthCode, login, resetPassword} from "../api/login";

  export default {
    name: 'arrange',
    data() {
      const validatePhone = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('请输入手机号'))
        }
        if (value.length < 11) {
          return callback(new Error('请输入正确的11位手机号'))
        }
        if (!/^1[3-9]\d{9}$/.test(value)) {
          return callback(new Error('手机号错误'))
        }
        callback()
      }
      const validatePassword = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('请输入新密码'))
        }
        if (!this.common.validatePassword(value)) {
          return callback(new Error('密码必须包含字母、数字和特殊字符，并在8~25位之间！'))
        }
        callback()
      }
      const validateAuthCode = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('请输入验证码'))
        }
        if (!/^\d{6}$/.test(value)) {
          return callback(new Error('验证码为6位数字格式'))
        }
        callback()
      }
      return {
        loading: false,
        type: 1,  // 1.登录 2.二维码 3.忘记密码 4.安全验证滑块
        loginForm: {
          phone: '',
          psd: ''
        },
        loginFormRules: {
          phone: [{required: true, validator: validatePhone, trigger: 'blur'}],
          psd: [{required: true, message: '请输入密码', trigger: 'blur'}],
        },
        resetForm: {
          phone: '',
          authCode: '',
          newPassword: ''
        },
        resetFormRules: {
          phone: [{required: true, validator: validatePhone, trigger: 'blur'}],
          authCode: [{required: true, validator: validateAuthCode, trigger: 'blur'}],
          newPassword: [{required: true, validator: validatePassword, trigger: 'blur'}],
        },
        keepAccount: false,
        nc: null,
        smsId: '',
        timer: null,
        authCodeText: '发送验证码',
        countdown: 60,
      }
    },
    created() {
      const sessionId = sessionStorage.getItem('sessionId')
      let yearSystem = sessionStorage.getItem('yearSystem')
      let schoolSection = sessionStorage.getItem('schoolSection')
      if (sessionId && (yearSystem || yearSystem == 0) && schoolSection) {
        this.$router.replace({name: 'home'})
        return
      }
      const now = new Date()
      const nowTime = now.getTime()
      const expireTime = localStorage.getItem('expireTime')
      if (expireTime) {
        if (expireTime < nowTime) {
          localStorage.removeItem('phone')
          localStorage.removeItem('password')
          localStorage.removeItem('expireTime')
        } else {
          this.loginForm.phone = localStorage.getItem('phone')
          this.loginForm.psd = localStorage.getItem('password')
          this.keepAccount = true
        }
      }
    },
    methods: {
      initNC() {
        getAliYunConfigParam('forgetPwd', this.resetForm.phone).then(res => {
          if (res.state == '11') {
            this.type = 4
            const {aliYunAppKey, aliYunScene} = res.data
            let self = this
            if (self.nc === null) {
              AWSC.use("nc", function (state, module) {
                // 初始化
                self.nc = module.init({
                  // 应用类型标识。它和使用场景标识（scene字段）一起决定了滑动验证的业务场景与后端对应使用的策略模型。您可以在阿里云验证码控制台的配置管理页签找到对应的appkey字段值，请务必正确填写。
                  appkey: aliYunAppKey,
                  //使用场景标识。它和应用类型标识（appkey字段）一起决定了滑动验证的业务场景与后端对应使用的策略模型。您可以在阿里云验证码控制台的配置管理页签找到对应的scene值，请务必正确填写。
                  scene: aliYunScene,
                  // 声明滑动验证需要渲染的目标ID。
                  renderTo: "nc",
                  // test: module.TEST_BLOCK, // test fail state
                  //前端滑动验证通过时会触发该回调参数。您可以在该回调参数中将会话ID（sessionId）、签名串（sig）、请求唯一标识（token）字段记录下来，随业务请求一同发送至您的服务端调用验签。
                  success: function (data) {
                    data.scene = aliYunScene;
                    self.callbackAuthCode(data)
                  },
                  // 滑动验证失败时触发该回调参数。
                  fail: function (failCode) {
                    console.log('fail: ', failCode)
                  },
                  // 验证码加载出现异常时触发该回调参数。
                  error: function (errorCode) {
                    console.log('error: ', errorCode)
                  }
                });
              })
            }
          }
        })
      },
      typeSwitch(type) {
        this.type = type
      },
      onLogin() {
        this.$refs['loginForm'].validate(valid => {
          if (valid) {
            this.loading = true
            login(this.loginForm.phone, this.loginForm.psd).then((res) => {
              this.loading = false
              if (res.state === '11') {
                if (this.keepAccount) {
                  if (this.keepAccountType) {
                    const now = new Date()
                    const nowTime = now.getTime()
                    const expireTime = nowTime + 604800000
                    localStorage.setItem('phone', this.loginForm.phone)
                    localStorage.setItem('password', this.loginForm.psd)
                    localStorage.setItem('expireTime', expireTime)
                  }
                } else {
                  localStorage.removeItem('phone')
                  localStorage.removeItem('password')
                  localStorage.removeItem('expireTime')
                }
                this.jumpToHome(res)
              }
            }).catch(() => {
              this.loading = false
            })
          }
        })
      },
      onReset() {
        const {authCode, phone, newPassword} = this.resetForm
        if (!this.common.validatePassword(newPassword)) {
          this.$message.warning('密码必须包含字母、数字和特殊字符，并在8~25位之间！')
          return
        }
        this.$refs['resetForm'].validate(valid => {
          if (valid) {
            resetPassword(authCode, phone, this.smsId, newPassword).then(res => {
              if (res.state === '11') {
                this.$refs['resetForm'].resetFields()
                this.jumpToHome(res)
              }
            })
          }
        })
      },
      jumpToHome(res) {
        this.$store.commit('clearInclude')
        const {data} = res
        data.gradeTypeId = data.section
        data.teacherId = data.id
        data.teacherName = data.name
        data.teacherHead = data.headPortrait
        data.teacherStatus = data.approvalStatus
        data.sessionId = data.authToken
        data.superAdmin = data.roleType
        this.$store.commit('setUserInfo', res.data)
        sessionStorage.setItem('teacherStatus', data.teacherStatus)
        sessionStorage.setItem('superAdmin', data.superAdmin)
        sessionStorage.setItem('sessionId', data.sessionId)
        sessionStorage.setItem('yearSystem', data.yearSystem)
        sessionStorage.setItem('schoolProvinceId', data.schoolProvinceId)
        sessionStorage.setItem('schoolSection', data.schoolSection)
        sessionStorage.setItem('teacherId', data.teacherId)
        sessionStorage.setItem('teacherName', data.name)
        sessionStorage.setItem('schoolName', data.schoolName)
        sessionStorage.setItem('teacherHead', data.teacherHead)
        sessionStorage.setItem('gradeTypeId', data.gradeTypeId)
        sessionStorage.setItem('provinceId', data.provinceId)
        sessionStorage.setItem('cityId', data.cityId)
        sessionStorage.setItem('schoolId', data.schoolId)
        sessionStorage.setItem('phone', data.phone)
        this.$router.push({name: 'home'})
      },
      validateAuthCode() {
        this.$refs['resetForm'].validateField('phone', (phoneError) => {
          if (!phoneError) {
            if (!this.timer) {
              this.initNC()
            }
          }
        })
      },
      callbackAuthCode(data) {
        this.type = 3
        this.nc.reset()
        if (!this.timer) {
          this.authCodeText = `${this.countdown}s`
          this.timer = setInterval(this._countDown, 1000)
          getAuthCode(data.sessionId, data.sig, data.token, data.scene, this.resetForm.phone).then(res => {
            if (res.state === '11') {
              this.smsId = res.data.smsId
            } else {
              clearInterval(this.timer)
              this.timer = null
              this.countdown = 60
              this.authCodeText = '发送验证码'
            }
          })
        }
      },
      _countDown() {
        this.countdown--
        if (this.countdown > 0) {
          this.authCodeText = `${this.countdown}s`
        } else {
          clearInterval(this.timer)
          this.timer = null
          this.countdown = 60
          this.authCodeText = '重新发送验证码'
        }
      },
      resetLocalStorage() {
        this.keepAccountType = true
      }
    }
  }
</script>

<style>
  .input-list .el-input__inner {
    background-color: #F6F6F6;
    height: 100%;
    padding-left: 38px;
  }

  .input-list .el-input__suffix {
    right: 15px;
  }

  .input-list .el-input-group__append {
    background-color: #F6F6F6;
  }

  .input-list .el-input-group--append .el-input__inner {
    border-right: 0;
  }

  .input-box input:-webkit-autofill {
    background-color: #F6F6F6;
  }

  input::-ms-reveal {
    display: none;
  }

  .login-box .el-form-item--small.el-form-item {
    margin-bottom: 24px !important;
  }
</style>
<style type="text/css">
  #nc {
    margin-top: 112px;
  }

  .nc-container #nc_1_wrapper {
    width: 308px !important;
    height: 44px !important;
    line-height: 44px !important;
    font-size: 15px !important;
  }

  .nc_iconfont,
  .btn_ok {
    width: 44px !important;
    font-size: 22px !important;
  }

  .nc_scale {
    height: 44px !important;
  }

  .nc-container .nc_scale span {
    height: 44px !important;
    line-height: 44px !important;
  }

  .nc-container .nc_scale .scale_text {
    line-height: 44px !important;
  }

  .nc-container .nc_wrapper .errloading {
    line-height: 28px !important;
    text-indent: 5px !important;
  }
</style>
<style lang="scss" scoped>
  .bg-f6 {
    background-color: #f6f6f6;
  }

  .header {
    background-color: #FFFFFF;
    width: 100%;

    .logo {
      max-width: 1400px;
      margin: 0 auto;

      img {
        display: block;
        width: auto;
        height: 80px;
      }
    }
  }

  .login-box {
    @include flex(flex-start, flex-end);
    background: #F6F6F6 url("../assets/login/bg.png") no-repeat center;
    background-size: auto 100%;
    height: 664px;

    .login-content {
      flex-grow: 1;
      @include flex(flex-end, flex-start);
      max-width: 1400px;
      margin: 0 auto;

      .carousel {
        width: 550px;
        height: 550px;
        margin-right: 150px;
      }

      .swiper-img {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .login-medium {
        background-color: #fff;
        width: 380px;
        height: 420px;
        border: 1px solid #C8C8C8;
        margin-top: 30px;
        margin-right: 100px;
        position: relative;

        .switch {
          @include flex;
          background-color: #F6F6F6;

          .list {
            flex-grow: 1;
            height: 68px;
            line-height: 68px;
            @include font(18px, #666, center);
            cursor: pointer;
          }

          .active {
            background-color: #fff;
            color: #000;
          }
        }

        .web {
          padding: 50px 36px 0;

          .login-btn {
            width: 100%;
            height: 44px;
            border-radius: 22px;
            margin-top: 16px;
            margin-bottom: 33px;
            font-size: 16px;
          }

          .space-between {
            @include flex(space-between);
            font-size: 16px;
            color: #999999;

            .forget-btn {
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }

        .ercode {
          width: 100%;
          height: 100%;

          img {
            display: block;
            width: 282px;
            height: 282px;
            margin: 35px auto 0;
          }
        }

        .reset {
          position: absolute;
          top: 0;
          left: 0;
          width: 378px;
          height: 418px;
          background-color: #ffffff;
          padding: 20px 35px;

          &-title {
            color: #000000;
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 30px;
          }


          &-btn__common {
            width: 100%;
            height: 44px;
            text-align: center;
            border-radius: 22px;
            font-size: 16px;
            margin-top: 6px;
          }

          &-btn__cancel {
            color: #999999;
            line-height: 44px;
            cursor: pointer;
            margin-top: 12px;

            &:hover {
              background-color: #F6F6F6;
            }
          }
        }

        .input-list {
          height: 50px;
          color: #999999;
          background-color: #C8C8C8;
          font-size: 16px;
          border-radius: 8px;
          /*margin-bottom: 20px;*/
        }

        .auth-code {
          height: 50px;
          line-height: 50px;
          text-align: right;
          font-size: 14px;
          color: #309AF2;
        }

        .cursor-pointer {
          cursor: pointer;
        }

        .cursor-not {
          cursor: not-allowed;
        }
      }
    }

    .icon-prefix {
      width: 20px;
      height: 20px;
      margin-left: 5px;
      margin-top: 15px;
    }
  }

  .content {
    max-width: 800px;
    padding: 80px 0 30px;
    margin: 0 auto;

    .column {
      @include flex(space-between);
      background-color: #fff;
      height: 160px;
      border-radius: 80px;
      margin-bottom: 50px;
      @include font(16px, #666);

      .left {
        flex-shrink: 0;
        width: 130px;
        height: 160px;
      }

      .right {
        flex-shrink: 0;
        width: 220px;
        height: 160px;
      }

      img {
        width: 100%;
        height: 100%;
      }

      .title {
        @include font(24px, #333);
        margin-bottom: 10px;
      }
    }
  }

  .footer {
    background-color: #fff;
    height: 270px;
    position: relative;

    .tips {
      background-color: #fff;
      width: 200px;
      height: 50px;
      line-height: 50px;
      border: 1px solid #C8C8C8;
      border-radius: 25px;
      @include font(22px, #333, center);
      position: absolute;
      top: -25px;
      left: 50%;
      transform: translateX(-50%);
    }

    .row {
      @include flex;
      padding-top: 32px;

      .col {
        width: 260px;
        @include font(16px, #666666, center);

        .img {
          width: 122px;
          height: 122px;
          margin: 0 auto 18px;
        }

        .bold {
          margin-bottom: 6px;
          @include font(20px, #000, center);
          font-weight: bold;
        }
      }

      .arrow {
        width: 40px;
        height: 40px;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
</style>
